/*
ヴェ	VU > ヴェ VE
フェ	FU > フェ FE
フォ	FE > フォ FO

下記の2つが同じ入力法になってるのですが、これはどうしましょう？
ジ	JI
ヂ	JI

*/
export let textMap = {
	arry: {
		"ア": "a",
		"イ": "i",
		"ウ": "u",
		"エ": "e",
		"オ": "o",
		
		"カ": "ka",
		"キ": "ki",
		"ク": "ku",
		"ケ": "ke",
		"コ": "ko",
		
		"サ": "sa",
		"シ": "shi",
		"ス": "su",
		"セ": "se",
		"ソ": "so",
		
		"タ": "ta",
		"チ": "chi",
		"ツ": "tsu",
		"テ": "te",
		"ト": "to",
		
		"ナ": "na",
		"ニ": "ni",
		"ヌ": "nu",
		"ネ": "ne",
		"ノ": "no",
		
		"ハ": "ha",
		"ヒ": "hi",
		"フ": "fu",
		"ヘ": "he",
		"ホ": "ho",
		
		"マ": "ma",
		"ミ": "mi",
		"ム": "mu",
		"メ": "me",
		"モ": "mo",
		
		"ヤ": "ya",
		"ユ": "yu",
		"ヨ": "yo",
		
		"ラ": "ra",
		"リ": "ri",
		"ル": "ru",
		"レ": "re",
		"ロ": "ro",
		
		"ワ": "wa",
		"ヲ": "o",
		"ン": "n",
		
		"ガ": "ga",
		"ギ": "gi",
		"グ": "gu",
		"ゲ": "ge",
		"ゴ": "go",
		
		"ザ": "za",
		"ジ": "ji",
		"ズ": "zu",
		"ゼ": "ze",
		"ゾ": "zo",
		
		"ダ": "da",
		"ヂ": "ji",
		"ヅ": "zu",
		"デ": "de",
		"ド": "do",
		
		"バ": "ba",
		"ビ": "bi",
		"ブ": "bu",
		"ベ": "be",
		"ボ": "bo",
		
		"パ": "pa",
		"ピ": "pi",
		"プ": "pu",
		"ペ": "pe",
		"ポ": "po",
		
		'キャ' : 'kya',
		'キィ' : 'kyi',
		'キュ' : 'kyu',
		'キェ' : 'kye',
		'キョ' : 'kyo',
		'クァ' : 'qa',
		'クィ' : 'qi',
		'クェ' : 'qe',
		'クォ' : 'qo',
		'クャ' : 'qya',
		'クュ' : 'qyu',
		'クョ' : 'qyo',
		'シャ' : "sha",
		'シィ' : 'syi',
		'シュ' : "shu",
		'シェ' : 'sye',
		'ショ' : "sho",
		'チャ' : "cha",
		'チィ' : "tyi",
		'チュ' : "chu",
		'チェ' : 'che',
		'チョ' : "cho",
		'テャ' : 'tha',
		'ティ' : 'thi',
		'テュ' : 'thu',
		'テェ' : 'the',
		'テョ' : 'tho',
		'ヒャ' : 'hya',
		'ヒィ' : 'hyi',
		'ヒュ' : 'hyu',
		'ヒェ' : 'hye',
		'ヒョ' : 'hyo',
		'ファ' : 'fa',
		'フィ' : 'fi',
		'フェ' : 'fe',
		'フォ' : 'fo',
		'ミャ' : 'mya',
		'ミィ' : 'myi',
		'ミュ' : 'myu',
		'ミェ' : 'mye',
		'ミョ' : 'myo',
		'ヴァ' : 'va',
		'ヴィ' : 'vi',
		'ヴェ' : 've',
		'ヴォ' : 'vo',
		'ギャ' : 'gya',
		'ギィ' : 'gyi',
		'ギュ' : 'gyu',
		'ギェ' : 'gye',
		'ギョ' : 'gyo',
		'ジャ' : "ja",
		'ジィ' : 'zyi',
		'ジュ' : "ju",
		'ジェ' : 'zye',
		'ジョ' : "jo",
		'ヂャ' : "ja",
		'ヂュ' : 'ju',
		'ヂェ' : 'je',
		'ヂョ' : 'jo',
		'ビャ' : 'bya',
		'ビュ' : 'byu',
		'ビェ' : 'bye',
		'ビョ' : 'byo',
		
		'ピャ' : 'pya',
		'ピィ' : 'pyi',
		'ピュ' : 'pyu',
		'ピェ' : 'pye',
		'ピョ' : 'pyo',
		
		'ニィ' : 'nii',
		'ニャ' : 'nya',
		'ニュ' : 'nyu',
		'ニョ' : 'nyo',
		
		'リャ' : 'rya',
		'リュ' : 'ryu',
		'リョ' : 'ryo',
		
		'ウィ' : 'wi',
		'ウェ' : 'we',
		'ウォ' : 'wo',
		'ヴァ' : 'va',
		'ヴィ' : 'vi',
		'ヴェ' : 've',
		'ヴォ' : 'vo',
		
		'デャ' : 'dha',
		'ディ' : 'dhi',
		'デュ' : 'dhu',
		'デョ' : 'dho',
		
		'ティ' : 'tyi',
		
		'ニィ' : 'nyi',
		'ニェ' : 'nye',
		
		'ファ' : 'fa',
		'フィ' : 'fi',
		'フェ' : 'fe',
		'フォ' : 'fo',
		
		'ー' : '-',
		'、' : ', ',
		'，' : ', ',
		'。' : '.',
		
		'★' : '☆',
		'☆' : '☆',
		'！': '!',
		'!': '!',
		'？' : '?',
		'?' : '?',
		'＆' : '&',
		'&' : '&',
		'＝': '=',
		
		'0' : '0',
		'1' : '1',
		'2' : '2',
		'3' : '3',
		'4' : '4',
		'5' : '5',
		'6' : '6',
		'7' : '7',
		'8' : '8',
		'9' : '9',
		
		
		'ハイ': "HI",
		'チュウ': "CHEW",
		'ハイチュウ': "HI-CHEW"
	}
}