import { api } from './_api.js';
export let result = {
	init: function(){
		result.nav();
		//console.log("result page");
	},
	nav: function(){
		let $shareX = document.querySelector("a[data-twitter]");
		$shareX.removeEventListener("click", result.share, false);
		$shareX.addEventListener("click", result.share, false);
	},
	share: function(e){
		e.preventDefault();
		//シェア時のカウントアップ
		
		
		let $this = this;
		let txt = `日本のハイチュウは、世界のHI-CHEW へ。
#HICHEWロゴメーカー であなたの名前を変換して遊んでみよう！`;
		//https://nama-maker.campaign.suntory.co.jp
		//let url = getMyResult.shareURL;
		let url = location.href;
		let hashtags = "HICHEWロゴメーカー";
		let urlArry = url.split("/");
		let code = urlArry[urlArry.length - 1];
		console.log("%cShare > ","color:white; background-color: #F23C3B ; padding:2px; border-radius:4px;", code);
		api.shareCountUp(code);
		
		window.open(
			'https://twitter.com/intent/tweet?url=' + url + "&text=" + encodeURIComponent(txt) + "&hashtags=" + hashtags,
			'twittersharedialog', 'width=626,height=640');
	},
	download: function(){
		/*
		let cvs 	= document.querySelector("canvas#canvas_result");
		let link 	= document.createElement("a");
		link.href 		= cvs.toDataURL("image/jpeg", 1);
		link.download 	= "hi-chew-logo_maker.jpg";
		link.click();
		*/
	}
}