class Carousel {
	constructor($dom){
		this.dom 		= $dom;
		this.target 	= $dom.querySelector("ul");
		this.current 	= 1;
		this.max 		= 6;
		this.move();
		let $this = this;
		setTimeout(function(){
			$this.target.classList.remove("notransition");
		}, 100);
	}
	inc(){
		this.current++;
		this.move();
	}
	move(){
		//console.log(this.current, this.max);
		let $this = this;
		let w = this.target.querySelectorAll("li")[0].clientWidth;
		let padding = w*(1 - 0.9789);
		let winW = document.querySelector("#wrap_r").clientWidth;
		let diff = (winW - w)/2;
		let x = Math.floor( this.current*-w + diff + padding/2 );
		$this.target.style.transform = `translate3d(${x}px, 0, 0)`;
		
		setTimeout(function(){
			if($this.current === $this.max){
				$this.target.classList.add("notransition");
				setTimeout(function(){
					$this.current = 1;
					let backX = Math.floor( $this.current*-w + diff + padding/2 );
					$this.target.style.transform = `translate3d(${backX}px, 0, 0)`;
					setTimeout(function(){
						$this.target.classList.remove("notransition");
					}, 100);
				}, 50);
			}
		}, 600);
	}
}
export let slideshow = {
	doms: [],
	arry: [],
	duration: 2000,
	start: 0,
	init: function(){
		slideshow.doms = document.querySelectorAll("[data-carousel]");
		slideshow.doms.forEach( ($slide, index) => {
			slideshow.arry[index] = new Carousel($slide);
		});
		slideshow.start = slideshow.getNow();
		slideshow.timer = setInterval( function(){
			slideshow.check();
		}, 100);
	},
	check: function(){
		let diff = slideshow.getNow() - slideshow.start;
		if(diff > slideshow.duration){
			slideshow.start = slideshow.getNow();
			for(let i = 0; i < slideshow.arry.length; i++){
				slideshow.arry[i].inc();
			}
		}
	},
	getNow: function(){
		let n = new Date();
		return n.getTime();
	}
}