export const load = {
	getJson: function(url, params){
		//console.log("getJson:", url);
		return fetch(url).then( function(res){
			return res.json();
		}).then(function(data){
			return data;
		}).catch(function(error){
			return error
		});
	},
	getHeaderJson: function(url, headers){
		//console.log("getJson:", url, headers);
		const options = {
			method: 'GET',
			headers: headers
		}
		return fetch(url, options).then( function(res){
			return res.json();
		}).then(function(data){
			return data;
		});
	},
	postJson: function(url, param, headers){
		const options = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(param)
		}
		//console.log(options)
		return fetch(url, options).then( function(res){
			return res.json();
		}).then(function(data){
			return data;
		}).catch(function(error){
			return error
		});
	}
}