import { api } from './_api.js';
import { textMap } from './_textMap.js';

export let genForm = {
	target: "",
	button: "",
	agreeButton: "",
	currentText: "",
	isChanging: false,
	init: function(){
		genForm.target 		= document.querySelector("[data-input-text]");
		genForm.enTarget 	= document.querySelector("[data-input-english]");
		genForm.button 		= document.querySelector("button[data-submit]");
		genForm.agreeButton = document.querySelector(`.agree input[type="checkbox"]`)
		genForm.nav();
	},
	nav: function(){
		//console.log(genForm.enTarget);
		genForm.target.removeEventListener("change", genForm.checkNumber, false);
		genForm.target.addEventListener("change", genForm.checkNumber, false);
		
		if ('CompositionEvent' in window) {
			genForm.target.removeEventListener("compositionend", genForm.check, false);
			genForm.target.addEventListener("compositionend", genForm.check, false);
			
			genForm.target.removeEventListener("compositionend", genForm.changeToEn, false);
			genForm.target.addEventListener("compositionend", genForm.changeToEn, false);
			//english
			genForm.enTarget.removeEventListener("input", genForm.check, false);
			genForm.enTarget.addEventListener("input", genForm.check, false);
		}else{
			genForm.target.removeEventListener("input", genForm.check, false);
			genForm.target.addEventListener("input", genForm.check, false);
			
			genForm.target.removeEventListener("input", genForm.changeToEn, false);
			genForm.target.addEventListener("input", genForm.changeToEn, false);
			
			//english
			genForm.enTarget.removeEventListener("input", genForm.check, false);
			genForm.enTarget.addEventListener("input", genForm.check, false);
		}
		
		genForm.agreeButton.removeEventListener("change", genForm.check, false);
		genForm.agreeButton.addEventListener("change", genForm.check, false);
	},
	check: function(){
		let val = genForm.target.value;
		//console.log("check", val);
		//カタカナ（全角・半角）
		//let regex = /^[\u30A0-\u30FFｧ-ﾝﾞﾟ]*$/;
		//let regex = /^[\u30A0-\u30FFｧ-ﾝﾞﾟ★☆!?=！？＆＝\&0-9０１２３４５６７８９]*$/;
		let regex = /^[\u30A0-\u30FFｧ-ﾝﾞﾟ★☆!?=！？＆＝\&0-9０１２３４５６７８９]*$/;
		//カタカナ（全角）
		//let regex = /^[\u30A0-\u30FF]*$/
		let isKatakana = false;
		if(val != ""){
			
			if( regex.test(val) ){
				//console.log("カタカナです", val);
				//半角だったら全角に
				let thisVal = numFulltoHalf(val);
				thisVal = kanaHalfToFull(thisVal);
				thisVal = convertToZenkaku(thisVal);
				console.log("%ckana half > full","color:white; background-color: #138D75 ; padding:2px; border-radius:4px;", thisVal);
				genForm.target.value = thisVal;
				genForm.hideError();
				isKatakana = true;
			}else{
				genForm.toKatakana(genForm.target);
				val = genForm.target.value;
				if( regex.test(val) ){
					isKatakana = true;
				}
				//genForm.showError("kana");
				//console.log("カタカナではない文字が入ってます。", val)
			}
		}else{
			genForm.hideError();
			//console.log("空です")
		}
		let isAgree = genForm.agreeButton.checked;
		//console.log("isAgree:", isAgree);
		
		let $enInput 	= document.querySelector(`input[data-input-english]`);
		let enValue 	= $enInput.value;
		let isEnglish 	= checkEn(enValue);
		//console.log("isEnglish:", isEnglish, enValue);
		function　checkEn(text){
			//console.log("英語文字数", text.length);
			if(text.length > 0){
				//console.log(text);
				//let engRegex = /[^a-z\-]/gi
				let validRegex = /^[a-zA-Z0-9☆!?&=-]*$/;
				if (text.match(validRegex)) {
					return true;
				} else {
					return false;
				}
			}else{
				return false
			}
		}
		//console.log("check:", isKatakana, isAgree, isEnglish);
		if(isKatakana && isAgree && isEnglish){
			genForm.hideError();
			if(isAgree){
				if( genForm.button.classList.contains("disable") ){
					genForm.button.classList.remove("disable");
				}
			}
		}else{
			if( !genForm.button.classList.contains("disable") ){
				genForm.button.classList.add("disable");
			}
		}
	},
	toKatakana: function(){
		let katakana = "";
		let v = genForm.target.value;
		//console.log(v)
		genForm.target.value = "";
		v = convertToZenkaku(v);
		//let regex = /[ぁ-ん]/g;
		let regex = /[\u3041-\u3096☆=！？＆!?&＝\uFF10-\uFF19]/g;
		katakana = v.replace(regex, function(match) {
			//return String.fromCharCode(s.charCodeAt(0) + 0x60);
			if (match >= '\u3041' && match <= '\u3096') {
				return String.fromCharCode(match.charCodeAt(0) + 0x60);
			} 
			// その他の文字はそのまま返す
			else {
				return match;
			}
		});
		genForm.target.value = katakana;
		//convertToZenkaku(genForm.target);
		//console.log(katakana);
		//genForm.target.value = katakana;
	},
	changeToEn: function(){
		let kanaVal = genForm.target.value;
		//console.log(kanaVal);
		//カタカナ（全角・半角）
		//let regex = /^[\u30A0-\u30FFｧ-ﾝﾞﾟ]*$/;
		kanaVal = numFulltoHalf(kanaVal);
		let regex = /^[\u30A0-\u30FFｧ-ﾝﾞﾟ★☆!?=！？＆＝\&0-9]*$/;
		//カタカナ（全角）
		//let regex = /^[\u30A0-\u30FF]*$/
		let isKatakana = false;
		let count = 0;
		let len = kanaVal.length;
		let chengedArry = [];
		let englishText = [];
		//いくつの文字の塊まで検索するか
		let checkLen = 5;
		
		for(let i = 0; i < len; i++){
			chengedArry.push(0);
			englishText.push("");
		}
		if(kanaVal != ""){
			if( regex.test(kanaVal) ){
				genForm.currentText = kanaVal;
				console.log("%cto english","color:white; background-color: #F23C3B ; padding:2px; border-radius:4px;", kanaVal);
				//console.log(kanaVal);
				//console.log("arry:", chengedArry, "len:", len);
				checks();
				//console.log("arry:", chengedArry);
			}else{
			}
		}
		
		function checks(){
			if(len >= checkLen){
				if(count <= len-checkLen){
					if(chengedArry[count] === 0){
						let txt = kanaVal.slice(count, count+checkLen);
						//console.log("t:", txt, count);
						if(txt === "ッ"){
							englishText[count] = "ッ";
							//console.log("一致")
						}
						for (let key in textMap.arry) {
							//console.log(key, txt);
							if(key === txt){
								//console.log("match:", textMap.arry[key], "count:", count);
								englishText[count] = textMap.arry[key];
								for(let j = count; j < count+checkLen; j++){
									chengedArry[j] = 1;
								}
							}
						}
					}else{
						//精査済み
					}
					count++;
					checks();
				}else{
					//console.log("checks text Count ", checkLen, " is end -----------------------");
					//現在の文字数でのチェックが終わったので、文字の塊を一つ減らしてさらに検索
					checkLen -=1;
					count = 0;
					if(checkLen > 0){
						checks();
					}
				}
			}else{
				checkLen -=1;
				count = 0;
				if(checkLen > 0){
					checks();
				}
			}
		}
		
		genForm.inputEnglish(englishText);
	},
	inputEnglish: function(englishText){
		let text = "";
		for(let i = 0; i < englishText.length; i++){
			text += englishText[i];
		}
		let textEn = "";
		for(let e = 0; e < text.length; e++){
			let t = text[e];
			if(t === "ッ"){
				//console.log("here", text[e+1]);
				if(text[e+1]){
					t = text[e+1];
				}else{
					t = "";
				}
				
			}
			textEn += t;
			//console.log(t);
		}
		
		document.querySelector("input[data-input-english]").value = textEn.toUpperCase();
	},
	showError: function(type){
		let $error = document.querySelector("#error");
		let $errorTxt = $error.querySelectorAll("p");
		let kanaInput = document.querySelector(`[data-input="katakana"] dd`);
		let removeCls = function(){
			$errorTxt.forEach( (txt) => {
				if( txt.classList.contains("active") ){
					txt.classList.remove("active");
				}
			});
		}
		
		if(type === "kana"){
			let $txt = $errorTxt[0];
			if( !$txt.classList.contains("active") ){
				removeCls();
				$txt.classList.add("active");
			}
			if( !kanaInput.classList.contains("has_error") ){
				kanaInput.classList.add("has_error");
			}
		}else if(type === "gen"){
			let $txt = $errorTxt[1];
			if( !$txt.classList.contains("active") ){
				removeCls();
				$txt.classList.add("active");
			}
		}
		$error.classList.add("show");
	},
	hideError: function(){
		let $error = document.querySelector("#error");
		if( $error.classList.contains("show") ){
			$error.classList.remove("show");
			document.querySelector(`[data-input="katakana"] dd`).classList.remove("has_error");
		}
	},
	checkJa: function(){
		let katakana = document.querySelector("input[data-input-text]").value;
		//console.log(katakana)
		genForm.toKatakana();
	},
	checkNumber: function(){
		let str = genForm.target.value;
		let henkan = convertToZenkaku(str);
		genForm.target.value = henkan;
		genForm.changeToEn();
	}
}

function kanaHalfToFull(str) {
	var kanaMap = {
		'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
		'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
		'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
		'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
		'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
		'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
		'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
		'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
		'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
		'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
		'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
		'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
		'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
		'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
		'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
		'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
		'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
		'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
		'｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
	};

	var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
	return str.replace(reg, function (match) {
		return kanaMap[match];
	}).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
};
function numFulltoHalf(str){
	return str.replace(/[\uFF10-\uFF19]/g, function(match) {
		// 全角数字を半角数字に変換
		return String.fromCharCode(match.charCodeAt(0) - 0xFEE0);
	});
}
function convertToZenkaku(str) {
	return str.replace(/[0-9]/g, function(match) {
		return String.fromCharCode(match.charCodeAt(0) + 0xFEE0);
	});
}