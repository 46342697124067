import { api } from './_api.js';
/*
TODO
チェック時にパッケージのIDを投げなければいけないか確認
最後はユーザーのtokenを投げるだけで良い？
*/
let user = {
	token: "",
	data: ["", ""],
	package: "",
	code: ""
}
export let generate = {
	myToken: "",
	userVal: [],
	startTimeMaxCount: 40,
	clickTimeMaxCount: 20,
	init: function(){
		api.getPostCount().then( (res) => {
			console.log("キュー:", res.pre);
			if(res.pre > generate.startTimeMaxCount){
				generate.showBusy();
			}else{
				api.getToken().then( (res) => {
					user.token = res;
					//console.log(user);
					generate.nav();
				});
			}
		});
	},
	nav: function(){
		let $submit = document.querySelector("[data-submit]");
		$submit.removeEventListener("click", generate.getVal, false);
		$submit.addEventListener("click", generate.getVal, false);
	},
	checkJa: function(){
		let target = document.querySelector("input[data-input-text]");
		let katakana = "";
		let v = target.value;
		//console.log(v)
		target.value = "";
		katakana = v.replace(/[ぁ-ん]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) + 0x60);
		});
		target.value = katakana;
	},
	getVal: function(){
		generate.checkJa();
		//return false
		
		/*
			2024.06.13
			サーバサイドに投げるデータをすりあわせる
			!?&0-9は半角、☆★は全角で打診中
			//busyチェックのあとに2問目？
		*/
		let katakana = document.querySelector("input[data-input-text]").value;
		let eigo 	= document.querySelector("input[data-input-english]").value;
		user.data[0] = katakana;
		user.data[1] = eigo;
		
		let packageSelectors = document.querySelectorAll(`input[name="package"]`);
		let selectedPackage = "";
		packageSelectors.forEach( (p, index) => {
			if( p.checked ){
				user.package = p.value;
			}
		});
		console.log(user);
		//return false
		
		generate.sendToAPI();
	},
	showBusy: function(){
		let $html = document.documentElement;
		if( !$html.classList.contains("show_busy") ){
			$html.classList.add("show_busy");
		}
	},
	sendToAPI: function(){
		/*
		let katakana 	= document.querySelector("input[data-input-text]").value;
		let eigo 		= document.querySelector("input[data-input-english]").value;
		user.data[0] = katakana;
		user.data[1] = eigo;
		
		*/
		console.log("%csend > ","color:white; background-color: #F23C3B ; padding:2px; border-radius:4px;", user);
		//return false
		api.send( user ).then( (res) => {
			let count = res.pre;
			//console.log(count);
			if(count >= generate.clickTimeMaxCount){
				let $loadingTxt = document.querySelector("#modal_loading dl");
				if( !$loadingTxt.classList.contains("is_busy") ){
					$loadingTxt.classList.add("is_busy");
				}
			}
			
			let $kana 	= document.querySelector(`[data-input="katakana"] dd.input_text`);
			let $en 	= document.querySelector(`[data-input="english"] dd.input_text`);
			//一旦エラー削除
			if( $kana.classList.contains("has_error") ){
				$kana.classList.remove("has_error");
			}
			if( $en.classList.contains("has_error") ){
				$en.classList.remove("has_error");
			}
			//console.log("post user value", res);
			if(res.error === "bad_request"){
				let errors = res.errors;
				for(let i = 0; i < errors.length; i++){
					let e = errors[i];
					
					if(e.key === "katakana"){
						if( !$kana.classList.contains("has_error") ){
							$kana.classList.add("has_error");
						}
					}
					if(e.key === "romaji"){
						if( !$en.classList.contains("has_error") ){
							$en.classList.add("has_error");
						}
					}
				}
				let $error = document.querySelector("#error");
				let $errorTxt = $error.querySelectorAll("p");
				
				let $txt = $errorTxt[1];
				if( !$txt.classList.contains("active") ){
					$txt.classList.add("active");
				}
				$error.classList.add("show");
			}else if(res.error === "unauthorized"){
				alert("もう一度はじめからやりなおしてください。")
				location.reload();
			}else{
				//not busy
				user.code = res.code;
				document.documentElement.classList.add("loading");
				
				setTimeout(function(){
					checkGenerate();
				}, 100);
				function checkGenerate(){
					api.checkGenerate(user.code).then( (result) => {
						//console.log(result);
						if(result.published){
							//let resultURL = result.share_url;
							//console.log("finish generate", resultURL);
							location.href = result.share_url;
						}else{
							if(result.state === "error"){
								//もう一度POST
								generate.getVal();
							}else{
								setTimeout(function(){
									checkGenerate();
								}, 500);
							}
						}
					});
				}
			}
		});
	}
}

let packageQuestion = {
	target: null,
	init: function(){
		packageQuestion.target =  document.querySelector("main");
		if( !packageQuestion.target.classList.contains("active_step2") ){
			packageQuestion.target.classList.remove("active_step1");
			packageQuestion.target.classList.add("active_step2");
			packageQuestion.nav();
		}
	},
	nav: function(){
		let $btns = document.querySelectorAll("[data-select-package] ul li button");
		$btns.forEach( ($btn) => {
			$btn.removeEventListener("click", packageQuestion.select, false);
			$btn.addEventListener("click", packageQuestion.select, false);
		});
		let $back = document.querySelector(".back button[data-back]");
		$back.removeEventListener("click", packageQuestion.back, false);
		$back.addEventListener("click", packageQuestion.back, false);
	},
	select: function(e){
		let selected = this.getAttribute("data-package");
		generate.sendToAPI(selected);
		
		console.log("%cselected package > ","color:white; background-color: #F23C3B ; padding:2px; border-radius:4px;", selected);
	},
	back: function(e){
		e.preventDefault();
		if( packageQuestion.target.classList.contains("active_step2") ){
			packageQuestion.target.classList.remove("active_step2");
			packageQuestion.target.classList.add("active_step1");
		}
	}
}