import { load } from './_load.js';

//-let apiURL = "http://hi-chew-logomaker-api-518296300.ap-northeast-1.elb.amazonaws.com/";
let apiURL = "https://stg-api.hi-chew-logomaker.jp/";
let domain = location.hostname;
//console.log(domain);
if( domain.includes("stg") || domain.includes(".local") ){
	
}else{
	apiURL = "https://api.hi-chew-logomaker.jp/"
}
//console.log(apiURL);
export let api = {
	url: "",
	getPostCount: function(){
		return new Promise((resolve, reject) => {
			let url = apiURL + "post_count.json";
			load.getJson(url).then( (res) => {
				resolve(res);
			});
		});
	},
	getToken: function(){
		return new Promise((resolve, reject) => {
			let url = apiURL + "auth.json";
			load.postJson(url, {}, {}).then( (res) => {
				resolve(res.token);
			});
		});
	},
	send: function(user){
		return new Promise((resolve, reject) => {
			let url = apiURL + "/v2/posts.json";
			let params = {
				katakana: user.data[0],
				romaji: user.data[1].toUpperCase(),
				package_type: user.package
			}
			console.log(params, user.token)
			//user.token = "";
			//console.log(user.token);
			let header = {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${user.token}`
			}
			//console.log(url, params, header);
			load.postJson(url, params, header).then( (res) => {
				resolve(res);
			});
		});
	},
	checkGenerate: function(code){
		return new Promise((resolve, reject) => {
			let url = apiURL + `posts/${code}.json`;
			load.getJson(url).then( (res) => {
				resolve(res);
			});
		});
	},
	getRanking: function(){
		return new Promise((resolve, reject) => {
			let url = apiURL + "ranking.json";
			//let url = "/ranking.json";
			load.getJson(url, {}, {}).then( (res) => {
				resolve(res.ranking);
			});
		});
	},
	shareCountUp: function(code){
		return new Promise((resolve, reject) => {
			//let url = apiURL + "ranking.json";
			let url = apiURL + `posts/${code}/share.json`;
			load.postJson(url, {}, {}).then( (res) => {
				resolve(res.ranking);
			});
		});
	},
	messageValidation: function(user){
		return new Promise((resolve, reject) => {
			let url = apiURL + "v2/validate_post.json";
			let params = {
				katakana: user.data[0],
				romaji: user.data[1].toUpperCase()
			}
			
			//user.token = "";
			//console.log(user.token);
			let header = {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${user.token}`
			}
			//console.log(url, params, header);
			load.postJson(url, params, header).then( (res) => {
				resolve(res);
			});
		});
	}
}