import { preload } from './lib/_preload.js';
import { bg } from './lib/_bg.js';
import { loc } from './lib/_location.js';
import { genForm } from './lib/_form.js';
import { result } from './lib/_result.js';
import { common } from './_common.js';
import { slideshow } from './lib/_carousel.js';
import { generate } from './lib/_generate.js';

export class Framework {
	constructor(){
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		//console.log(this.pageinfo);
	}
	
	checkURL(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		common.init();
		common.nav();
		bg.init();
		let isPage = document.querySelector("body").getAttribute("data-page");
		if(isPage === "top"){
			genForm.init();
			generate.init();
			
		}else if(isPage === "result"){
			result.init();
		}
		setTimeout(function(){
			let $body = document.querySelector("body");
			$body.classList.remove("disable");
			if(isPage === "top"){
				setTimeout(function(){
					slideshow.init();
				}, 1000);
			}
			setTimeout(function(){
				$body.classList.add("active");
			});
			common.resize();
		}, 300);
	}
}