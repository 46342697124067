import Granim from 'granim';

export let bg = {
	cvs: "",
	ctx: "",
	gradient: null,
	init: function(){
		var granimInstance = new Granim({
			element: '#cvs',
			direction: 'left-right',
			isPausedWhenNotInView: true,
			states : {
				"default-state": {
					gradients: [
						['#CB9DEB', '#FFECA8','#CDE9C9', '#F9BDAA'],
						['#F9BDAA', '#CB9DEB', '#FFECA8', '#CDE9C9'],
						['#CDE9C9', '#F9BDAA', '#CB9DEB', '#FFECA8'],
						['#FFECA8', '#CDE9C9', '#F9BDAA', '#CB9DEB']
					],
					transitionSpeed: 3000,
					loop: true
				}
			}
		});
	}
}